import React, { Component } from 'react';
import './visitor.css';
import GoogleMapReact from 'google-map-react';
import Brick from '../../components/map/brick.js';
import Entrance from '../../components/map/entrance.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as algoliasearch from 'algoliasearch'

//constants to access our Algolia index
const ALGOLIA_ID = 'HU0TSEBJG4';
const ALGOLIA_SEARCH_KEY = 'ff17e0f1dbc1414ec3b67841109de584';

//reference to the specific Algolia "bricks" index
const ALGOLIA_INDEX_NAME = 'bricks';
var client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY, { protocol: 'https:' });
var index = client.initIndex(ALGOLIA_INDEX_NAME)

class Visitor extends Component {
  constructor() {
    super();
    this.state = {
      // Current location: the device's location
      currentLocation: null,
      // Zoom: default zoom for Google Maps component
      zoom: 20,
      // Inscription: Inscription that is being typed/of the brick that was selected
      inscription: '',
      // results: search results returned from algolia
      results: [],
      // selectedBrick: brick that was selected
      selectedBrick: null,
      // searched: saves search query for going back and forth between search page and individual brick show pages
      searched: '',
      // byDonor: search category type
      byDonor: null,
      // widthPage: width of the window the application is being run on
      widthPage: window.innerWidth,
      // Phipps Garden Coordinates are pre-populated
      defaultCenter: {
        lat: 40.43920267930719,
        lng: -79.9481821247
      }
    }
  }

  render() {
    //create a list of li elements under the search bar, one li for every query result
    let num = -1;

    // these variables stores the results from the search query
    let resultList = []
    let donorList = []

    // hasTyped keeps track of whether the user has started typing
    const hasTyped = this.state.inscription

    // if the user has selected a brick, set the donor name
    var donorNames = null
    if (this.state.selectedBrick !== null) donorNames = this.state.selectedBrick.donorName

    //If nothing has been entered to search, show search prompt
    var promptTitle = "Search by Donor:"
    if (!this.state.byDonor) promptTitle = "Search by Inscription:"
    var prompting = "Enter Donor Name here..."
    if (!this.state.byDonor) prompting = "Enter Inscription here..."
    var searchByEmpty = "inscription"
    if (!this.state.byDonor) searchByEmpty = "donor"

    // these are the heights of the search results if the page is wide
    let inscriptionHeight = ["470px", "560px", "650px", "740px", "830px"]
    let donorHeight = ["470px", "560px", "650px", "740px", "830px"]
    // these are the heights of the search results if the page is not  wide
    if (this.state.widthPage < 755) {
      inscriptionHeight = ["410px", "490px", "570px", "650px", "730px"]
      donorHeight = ["390px", "470px", "550px", "630px", "710px"]
    }

    //render results for search, and add them to resultList and donorList
    for (let i in this.state.results) {
      num = num+1
      resultList.push(
        <li key={i} style={{top:inscriptionHeight[num]}} className="resultList" onClick={e => this.setBrick(e, this.state.results[i])}>
          <div>{this.state.results[i].inscription}</div>
        </li>
      )
      donorList.push(
        <li key={i} style={{top:donorHeight[num]}} className="donorList" onClick={e => this.setBrick(e, this.state.results[i])}>
          <div className="donorNameText">{this.state.results[i].donorName}</div>
          <div className="arrow bounce"><FontAwesomeIcon icon="arrow-right" /></div>
        </li>
      )
    }

    // this is what the render function will actually show on the page
    return (
      <div className="Visitor">
        <header className="visitor-header" >

          { /* sets up the top part of the application (the logo, the title, and the beige bar) */ }
          <div className="topBar"></div>
          <div className="applicationTitle">Discovery Garden Brick Directory</div>
          <img src={require('./homeLogo.png')} onClick={e => this.goBack(e)} className="logoHome" alt="logo"></img>

          {/* if the user hasn't started searching, display photo of the garden */ }
          <div>
          {this.state.selectedBrick == null && !hasTyped ? (
            <img src={require('./vic.jpg')} className="background" alt="logo" />)
          : null}
          </div>

          { /* if the user has started searching for a brick but hasnt selected a brick, either show the results
           or tll the user to search by different category */ }
          <div>
           {this.state.selectedBrick == null && hasTyped ? (
            <div>
              <div className="navBarBox"></div>
              <div className="inputResultBar"></div>
              <div className="clearBackBar"></div>
              {donorList.length > 0 ? (
                <div>
                  <div className="resultPrompt">Select your Brick:</div> <br/> <br/>
                  <div className="searchResults">Donor Name:</div>
                  <div style={{left: "40%", "textAlign": "left"}} className="searchResults">Inscription:</div>
                  <ul>{donorList}</ul>
                  <ul>{resultList}</ul>
                </div> ) : (
              <div className="resultPrompt" style={{"width":"80%"}}>No results found, try searching by {searchByEmpty}</div>)}</div>
            ): null}
          </div>

          { /* if the user has chosen a search type (byDonor is set), then allow them to type the search, else ask them how they would like to search */ }
          <div>
            {this.state.selectedBrick == null ? (
              <div>
                {this.state.byDonor != null ? (
                  <div>
                    <div className="navBarBox"></div>
                    <div className="search">Change Search:</div>
                    {this.state.byDonor ? (
                      <div>
                        <div><button className="donorButtonSelected" onClick={e => this.searchByDonor(e)}>Donor</button></div>
                        <button className="inscriptionNotSelected" onClick={e => this.searchByIns(e)}>Inscription</button>
                      </div>
                    ) : (
                      <div>
                        <button className="inscriptionButtonSelected" onClick={e => this.searchByIns(e)}>Inscription</button>
                        <div><button className="donorNotSelected" onClick={e => this.searchByDonor(e)}>Donor</button></div>
                      </div>)
                    }
                    <div className="encapsulatingSearch">
                      <div className="searchPrompt">{promptTitle}</div>
                      <div className = "searchBarandButton" style={{display: 'flex', flexDirection: 'row'}}>
                        <input type="text" className="input" placeholder={prompting} value={this.state.inscription} onChange={e => setTimeout(this.handleChange(e), 1000)}></input> <br/>
                        <button className="newClear" onClick={e => this.clearInscription(e)}>Clear</button>
                      </div>
                    </div>
                    </div>
                ) : (
                  <div>
                    <div className="selectCategory">How would you like to find your brick?</div>
                    <button className="inscriptionButtonPrompt" onClick={e => this.searchByIns(e)}>Inscription</button>
                    <button className="donorButtonPrompt" onClick={e => this.searchByDonor(e)}>Donor</button>
                  </div>)
                }
              </div>
            ) :
              <div>
                <div className="showPageBar"></div>
                <div className="resultShowBox">
                  <div>Donor Name: {donorNames}</div>
                  <div>Inscription: {this.state.inscription}</div>
                  <div>Location:</div>
                </div>
                <div className="backButtonShadow">
                  <button className="backButton" onClick={e => this.goBack(e)}>Back</button>
                </div>
              </div>
            }
          </div>

          <br/><br/>
          {/* this renders the google map once a brick has been selected */}
          <div>
            {this.state.selectedBrick !== null ?
              <div className="googleMapsShow" style={{position: 'absolute', marginBottom: '5%', top: '455px', left: "2.5%", height: '425px', width: '95%'}}>
                  <GoogleMapReact
                      bootstrapURLKeys={{ key: "AIzaSyAuKTS9uDrprgTWqXmZ_C4GObJmRjtAbxw" }}
                      center={this.state.defaultCenter}
                      defaultZoom={this.state.zoom}
                      options={this.getMapOptions}
                      >
                      <Entrance lat={40.439046} lng={-79.948216} />
                      {this.state.selectedBrick !== null ?
                        <Brick
                        text={"Saved DB location"}
                        lat={this.state.selectedBrick.lat}
                        lng={this.state.selectedBrick.lng}
                        />
                      :
                        <div></div>
                      }
                  </GoogleMapReact>
              </div> : null }
          </div>

        </header>
      </div>
    )
  }

  // anytime the text area changes, update the state of this component to reflect the text in there
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      inscription: e.target.value,
      submitted: false,
    }
    // re-query the database with the next string in the text area
      ,() => this.updateSearchResults()
      );
  }

  searchByDonor = (e) => {
    this.setState({
      byDonor: true
    }, () => this.updateSearchResults())
  }

  searchByIns = (e) => {
    this.setState({
      byDonor: false
    }, () => this.updateSearchResults())
  }

  // clears the inscription input field
  clearInscription = (e) => {
    this.setState({
      inscription: '',
      results: []
    })
  }

  goBack = (e) => {
    this.setState({
      selectedBrick: null,
      inscription: this.state.searched
    })
  }

  // whatever brick is selected, update the state of this component to reflect its properties
  // if the brick has a location in the database, it will update the actualLocation of this state
  // and will re-center the map based on that location
  setBrick = (e, result) => {
    e.preventDefault();
    this.setState({
      searched: this.state.inscription,
      selectedBrick: result,
      inscription: result.inscription,
      actualLocation: {
        lat: result.lat,
        lng: result.lng,
        timeUpdated: result.timeUpdated
      },
      defaultCenter: {
        lat: result.lat,
        lng: result.lng
      }
    })
  }

  // query the Algolia "bricks" index and extract the first 5 results
  updateSearchResults = () => {
    if (this.state.byDonor) {
      index.search(this.state.inscription, {
        "restrictSearchableAttributes": "donorName"
      }).then((data) => {
        this.setState({
          results: data.hits.slice(0,5)
        })
      })
      }
    else {
      index.search(this.state.inscription, {
        "restrictSearchableAttributes": "inscription"
      }).then((data) => {
        this.setState({
          results: data.hits.slice(0,5)
        })
      })
    }
  }

  // sets up the Google map component with necessary properties
  getMapOptions = (maps: Maps) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        tilt: 0,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        mapTypeId: maps.MapTypeId.SATELLITE,
    };
}

}

export default Visitor;
