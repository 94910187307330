import React from 'react';
import PropTypes from 'prop-types';
import entrance from './entrance.png'

const Entrance = props => (
    <img alt="entrance" src={entrance} style={{position: 'absolute',  userSelect: 'none', width: '25px', height: '25px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></img> 
);

Entrance.defaultProps = {
  onClick: null,
};

Entrance.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Entrance;