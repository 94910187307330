import React, { Component } from 'react';
import '../../App.css';
import fire from '../../fire.js';

class Test extends Component {
	constructor() {
		super();
		this.state = {
		  success: false,
		  delete: false,
		  write: false,
		  update: false,
		  read: false
		}
	}

	render() {
		return (
			<div>
				<h1 style={{textAlign: "center"}}>Testing Firebase Functions - Check Console for status!</h1>
				<h4 style={{textAlign: "center"}}>{this.state.read ? "Read test passed!" : ""}</h4>
				<h4 style={{textAlign: "center"}}>{this.state.write ? "Write test passed!" : ""}</h4>
				<h4 style={{textAlign: "center"}}>{this.state.update ? "Update test passed!" : ""}</h4>
				<h4 style={{textAlign: "center"}}>{this.state.delete ? "Delete test passed!" : ""}</h4>
				<br/>
				<h2 style={{textAlign: "center"}}>{this.state.success ? "Tests passed!" : ""}</h2>

		  	</div>
		);
	}

	componentDidMount = () => {
		this.runTests()
	}

	// test write and delete (waits for write before delete)
	testWriteAndDelete = () => {
		let that = this;
		fire.firestore().collection("csvTest").add({
			inscription: "Test 1 Inscription",
			donorLastName: "Byrd",
			donorName: "Evan Byrd",
		}).then(function(doc) {
			fire.firestore().collection("csvTest").doc(doc.id).get().then(snapshot => {
				if (snapshot.exists && snapshot.data().donorLastName === 'Byrd') {
					console.log('Successfully created a new brick.')
					that.setState({
						write: true
					}, () => that.testDelete())
				} else {
					console.log('Failed to add new brick.')
				}
			});
		});
	}

	// test read
	testRead = () => {
		let that = this;
		fire.firestore().collection("csvTestRU").doc("ReadBrick").get().then(snapshot => {
			if (snapshot.data().inscription === 'readme') {
				console.log('Successfully read brick data.')
				that.setState({
					read: true
				})
			} else {
				console.log('Failed to read brick data.')
			}
		});
	}

	// test update
	testUpdate = () => {
		let that = this;
		fire.firestore().collection("csvTestRU").doc('UpdateBrick').update({
			inscription: "Updated Inscription"
		}).then(function() {
			fire.firestore().collection("csvTestRU").doc('UpdateBrick').get().then(snapshot => {
				if (snapshot.data().inscription === 'Updated Inscription') {
					console.log('Successfully updated brick.')
					that.setState({
						update: true
					})
				} else {
					console.log('Failed to update brick data.')
				}
			})
		})
	}

	// test delete
	testDelete = () => {
		let that = this;
		fire.firestore().collection("csvTest").limit(1).get().then(snapshot => {
			const snapDoc = snapshot.docs[0];
			fire.firestore().collection("csvTest").doc(snapDoc.id).delete().then(function() {
				console.log("Successfully deleted brick.");
				that.setState({
					success: true,
					delete: true
				})
			});
		});
	}

	runTests = () => {
		this.testWriteAndDelete();
		this.testRead();
		this.testUpdate();
	}
  }
  
export default Test;