import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

var config = {
  apiKey: "AIzaSyCuhXmnhorkBhDqVpHGKJhNntzNwtJhJ2w",
  authDomain: "phippsbricks-2faa1.firebaseapp.com",
  databaseURL: "https://phippsbricks-2faa1.firebaseio.com",
  projectId: "phippsbricks-2faa1",
  storageBucket: "phippsbricks-2faa1.appspot.com",
  messagingSenderId: "390302352621"
};

const fire = firebase.initializeApp(config);
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
export default fire;