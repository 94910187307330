import React, { Component } from 'react';
import '../../App.css';
import { styles } from './admin.css';
import fire from '../../fire.js';
import check from './check.png';
import missing from './missing.png';

import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import Brick from '../../components/map/brick.js';
import Person from '../../components/map/person.js';
// import bricks from '../../phippsbricks.json';
import * as algoliasearch from 'algoliasearch'

//constants to access our Algolia index
const ALGOLIA_ID = 'HU0TSEBJG4';
const ALGOLIA_ADMIN_KEY = '36a62982ea02482b8a539c6644852f27';
const ALGOLIA_SEARCH_KEY = 'ff17e0f1dbc1414ec3b67841109de584';

//reference to the specific Algolia "bricks" index
const ALGOLIA_INDEX_NAME = 'bricks';
const ALGOLIA_NEW_INDEX_NAME = 'newBricks';
var client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY, { protocol: 'https:' });
var index = client.initIndex(ALGOLIA_INDEX_NAME)

//admin keys to write to the Algolia database
var adminClient = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY, { protocol: 'https:' });
var altAdminIndex = adminClient.initIndex(ALGOLIA_NEW_INDEX_NAME)
var adminIndex = adminClient.initIndex(ALGOLIA_INDEX_NAME)


class Admin extends Component {
  constructor() {
    super();
    this.state = {
      // Current location: the device's location
      currentLocation: null,
      // Actual location: the user selected location on the map component
      brickLocation: null,
      clickedLocation: null,
      submitted: false,
      // boolean for if we are logging bricks or checking new bricks
      logging: true,
      //default zoom for Google Maps component
      zoom: 30,
      inscription: '',
      results: [],
      name: '',
      lastName: '',
      newBricks: [],
      selectedBrick: null,
      adding: false,
      accurate: true,
      newBrick: {
        inscription: '',
        donorLastName: '',
        donorName: '',
        inscribedLastNameOrOtherKeyword: '',
        section: ''
      },
      //Flag for telling the user they need to select a brick before updating the database
      pleaseSelectBrick: false,
      //Flag for telling the user they need to select a location before updating the database
      pleaseSelectLocation: false,
      // Phipps Garden Coordinates are pre-populated
      defaultCenter: {
        lat: 40.43920267930719,
        lng: -79.9481821247
      }
    }
  }

  render = () => {
    //create a list of li elements under the search bar, one li for every query result
    let resultList = [];
    let newBricks = [];
    for (let i in this.state.results) {
      let imgSrc = missing;
      if (this.state.results[i].hasOwnProperty("lat")) {
        imgSrc = check;
      }
      if (this.state.inscription.length > 0) {
        resultList.push(
          <li key={i} onClick={e => this.setBrick(e, this.state.results[i])}>
            <div style={{float: 'left', width: '100%'}}>{this.state.results[i].inscription}</div>
            <div >
              <img src={imgSrc} alt="logo" style={{width:'30px', height:'30px', float: 'left'}}></img>
            </div>
            <br/>
            <br/>
          </li>
        )
      }
    }
    for (let i = 0; i < this.state.newBricks.length; i++) {
      newBricks.push(
        <li key={i} onClick={e => this.setBrick(e, this.state.newBricks[i])}>
          <div style={{float: 'left', width: '100%'}}>{this.state.newBricks[i].inscription}</div>
          <br/>
          <br/>
        </li>
      )
    }
    // this is what the render function will actually show on the page
    return (
      <div className={styles}>
        <header className="App-header" >
          <button id="logoutbutton" onClick={e => this.props.logout(e)}>Logout</button>
          <br/>
          <br/>
          <br/>
          {/* Load the search bar with the results list under it */}
          <div style={{display: 'flex'}}>
          {this.state.isAdmin ?
            <div style={{display: 'flex', flexDirection: "row"}}>
              <button id="left" className={this.state.logging ? "selectedButton" : "unselectedButton"} onClick={e => this.logging(e)}>Bricks</button>
              <button id="right" className={!this.state.logging ? "selectedButton" : "unselectedButton"} onClick={e => this.newBricks(e)}>New</button>
            </div>
          :
            <div></div>
          }
          </div>
          { this.state.logging ?
          <div>
           <h3 style = {{left: '0% !important'}}>Select Brick:</h3>
          <input type="text" placeholder="Type a brick inscription..." value={this.state.inscription} onChange={e => setTimeout(this.handleChange(e), 1000)}></input>

          {this.state.adding ?
            <div>
              <button className="cancel" onClick={e => this.addingBrick(e)}>{"Cancel"}</button>
              <br/>
              <button id="addbrick2" onClick={e => this.pushNewBrickLocation(e)}>Add Brick to Database</button>
            </div>
          :
              <div className="list">
                <ul style={{listStyle: 'none', left: '0%', paddingLeft: '0', width: '100%'}}>
                  {resultList}
                </ul>
                {this.state.inscription.length > 0 ?
                  <div>
                   {this.state.inscription.length > 0 || this.state.results.length > 0 ?
                   <div>
                      <button className="clearadmin" onClick={e => this.clearInscription(e)}>Clear</button>
                    </div>
                    :
                      <div></div>
                    }
                    { (this.state.inscription.length > 0 && this.state.selectedBrick === null )  ?
                      <div>
                        <h5>--OR--</h5>
                        <button id = "addbrick" onClick={e => this.addingBrick(e)}>{"Add New Brick"}</button>
                      </div>
                      :
                      <div></div>
                    }
                    {this.state.selectedBrick !== null ?
                    <div>
                      <label>
                        Accurate?
                        <input
                          name="accurate"
                          type="checkbox"
                          checked={this.state.accurate}
                          onChange={e => this.handleInputChange(e)} />
                      </label>
                    </div>
                    :
                    <div></div>
                    }
                  </div>
                  :
                  <div>
                  </div>
                }
              </div>

          }
          {/* All possible alerts will appear below if their flags are triggered */}
          <h4>
          <div>{this.state.pleaseSelectBrick === true ? "Please choose a brick from the database first!" : ""}</div>
          <div>{this.state.pleaseSelectLocation === true ? "Please drop a pin on the map to update the brick location!" : ""}</div>
          <div>{this.state.selectedBrick !== null ?  "Brick Selected!" : ""}</div>
          { this.state.selectedBrick !== null && this.state.clickedLocation === null ?
            <div>{this.state.clickedLocation !== null ?  "New Location Selected!" : "Please Select a Location on the Map!"} </div>
          :
            <div></div>
          }
          <div style={{color: 'green'}}>{this.state.submitted ? "Updated Location!" : ""}</div>
          <br/>
          {/* If a brick has been selected, then a button to update its location in the database will appear*/}
          </h4>
          {/* The entire google map component. Most of this is default code */}
            <div style={{ height: '60vh', width: '80vw'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAuKTS9uDrprgTWqXmZ_C4GObJmRjtAbxw" }}
                    center={this.state.defaultCenter}
                    defaultZoom={this.state.zoom}
                    options={this.getMapOptions}
                    onClick={e => this.mapClicked(e)}
                    >
                    {this.state.currentLocation !== null ?
                      <Person
                      text={"Current location"}
                      lat={this.state.currentLocation.lat}
                      lng={this.state.currentLocation.lng}
                      />
                    :
                      <div></div>
                    }
                    {this.state.brickLocation !== null ?
                      <Brick
                      text={"Saved DB location"}
                      lat={this.state.brickLocation.lat}
                      lng={this.state.brickLocation.lng}
                      />
                    :
                      <div></div>
                    }
                    {this.state.clickedLocation !== null ?
                      <Brick
                      text={"Clicked location"}
                      lat={this.state.clickedLocation.lat}
                      lng={this.state.clickedLocation.lng}
                      />
                    :
                      <div></div>
                    }
                </GoogleMapReact>
            </div>
            {this.state.selectedBrick !== null && this.state.adding === false ?
              <div>
                {this.state.selectedBrick.lat !== undefined ?
                  <div>
                    { this.state.clickedLocation === null ?
                      <div>
                        <button className="grey">Edit Location</button>
                      </div>
                    :
                      <div>
                      <button onClick={e => this.pushBrickLocation(e)}>Edit Location</button>
                      </div>
                    }
                  </div>
                :
                  <button onClick={e => this.pushBrickLocation(e)}>Add Brick Location in Database</button>
                }
              </div>
              :
              <div></div>
            }
            <br/>
            </div>
            :
            // admin adding NEW brick mode code below:
            <div>
              <div>{this.state.alert !== undefined ? this.state.alert : null}</div>
              <input type="text" placeholder="Type a brick inscription..." value={this.state.inscription} onChange={e => setTimeout(this.handleChange(e), 1000)}></input>
              <div>
                <button className="clearadmin" onClick={e => this.clearInscription(e)}>Clear</button>
              </div>
              { this.state.selectedBrick !== null ?
              <div>
                <input type="text" placeholder="Donor name" name="name" value={this.state.name} onChange={e => this.handleInputChange(e)}></input>
                <input type="text" placeholder="Donor last name" name="lastName" value={this.state.lastName} onChange={e => this.handleInputChange(e)}></input>
                <br/>
                <br/>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{ height: '60vh', width: '80vw'}}>
                  <GoogleMapReact
                      bootstrapURLKeys={{ key: "AIzaSyAuKTS9uDrprgTWqXmZ_C4GObJmRjtAbxw" }}
                      center={this.state.defaultCenter}
                      defaultZoom={this.state.zoom}
                      options={this.getMapOptions}
                      onClick={e => this.mapClicked(e)}
                      >
                      {this.state.currentLocation !== null ?
                        <Person
                        text={"Current location"}
                        lat={this.state.currentLocation.lat}
                        lng={this.state.currentLocation.lng}
                        />
                      :
                        <div></div>
                      }
                      {this.state.brickLocation !== null ?
                        <Brick
                        text={"Saved DB location"}
                        lat={this.state.brickLocation.lat}
                        lng={this.state.brickLocation.lng}
                        />
                      :
                        <div></div>
                      }
                      {this.state.clickedLocation !== null ?
                        <Brick
                        text={"Clicked location"}
                        lat={this.state.clickedLocation.lat}
                        lng={this.state.clickedLocation.lng}
                        />
                      :
                        <div></div>
                      }
                  </GoogleMapReact>
                </div>
                <div>
                {this.state.selectedBrick !== null ?
                  <div>
                    {this.state.selectedBrick.lat !== undefined ?
                      <div>
                        <button onClick={e => this.updateNewBrickLocationAdmin(e)}>Confirm New Brick</button>
                      </div>
                    :
                      <div></div>
                    }
                  </div>
                  :
                  <div></div>
                }
                </div>
              </div>
              </div>
              :
                <div>
                  {newBricks}
                </div>
              }
            </div>
          }
        </header>
      </div>
    );
  }

  logging = (e) => {
    e.preventDefault();
    this.setState({
      logging: true
    }, () => this.clearInscription())
  }

  newBricks = (e) => {
    e.preventDefault();
    this.clearInscription()
    this.setState({
      logging: false
    }, () => this.updateSearchResults())
  }

  // clears the inscription input field
  clearInscription = (e) => {
    this.setState({
      inscription: '',
      selectedBrick: null,
      results: []
    })
  }

  //sets this.state.adding to true, so we can add a brick instead of update existing bricks
  addingBrick = (e) => {
    this.setState({
      adding: !this.state.adding,
      selectedBrick: null,
      submitted: false,
      pleaseSelectBrick: false,
      pleaseSelectLocation: false
    })
  }

  handleTextChange = (e) => {
    // let brick = this.state.newBrick;
    // brick
    this.setState(Object.assign(this.state.newBrick,{[e.target.name]:e.target.value}));
  }

  // anytime the text area changes, update the state of this component to reflect the text in there
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      inscription: e.target.value,
      submitted: false,
      brickLocation: null
    }
    // re-query the database with the next string in the text area
      ,() => this.updateSearchResults()
      );
  }

  // just a handler for the state change watcher when adding a NEW Brick
  handleInscriptionChange = (e) => {
    e.preventDefault();
    this.setState({
      inscription: e.target.value
    })
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  // whatever brick is selected, update the state of this component to reflect its properties
  // if the brick has a location in the database, it will update the actualLocation of this state
  // and will re-center the map based on that location
  setBrick = (e, result) => {
    e.preventDefault();
    this.setState({
      selectedBrick: result,
      inscription: result.inscription,
      results: [],
      brickLocation: {
        lat: result.lat,
        lng: result.lng,
        timeUpdated: result.timeUpdated
      },
      clickedLocation: null,
      defaultCenter: {
        lat: result.lat,
        lng: result.lng
      }
    })
  }

  updateNewBrickLocationAdmin = (e) => {
    e.preventDefault();
    let brickObject = this.state.selectedBrick;
    if (this.state.clickedLocation !== null) {
      brickObject.lat = this.state.clickedLocation.lat;
      brickObject.lng = this.state.clickedLocation.lng;
    }
    if (this.state.selectedBrick === null) {
      return
    }
    if (this.state.clickedLocation === null) {
      brickObject.lat = this.state.selectedBrick.lat;
      brickObject.lng = this.state.selectedBrick.lng;
    }
    // push everything from this state to the Firestore database
    let ID = this.state.selectedBrick.objectID
    e.preventDefault();
    let that = this;
    brickObject.timeUpdated = moment().format('MMMM Do YYYY, h:mm:ss a');
    brickObject.inscription = this.state.inscription
    brickObject.accurate = this.state.accurate;
    if (this.state.currentLocation !== null) {
      let db = fire.firestore();
      db.collection("newBricks").doc(ID).update({
        lat: brickObject.lat,
        lng: brickObject.lng,
        donorLastName: that.state.lastName,
        donorName: that.state.name,
        timeUpdated: brickObject.timeUpdated,
        updatedBy: that.props.user.email,
        added: true,
        accurate: true,
        inscription: brickObject.inscription
      })
      // update Algolia to reflect this change
      .then(function() {
          altAdminIndex.partialUpdateObject({
            lat: brickObject.lat,
            lng: brickObject.lng,
            donorName: that.state.name,
            donorLastName: that.state.lastName,
            timeUpdated: brickObject.timeUpdated,
            inscription: brickObject.inscription,
            objectID: brickObject.objectID,
            accurate: true,
            added: true,
            updatedBy: that.props.user.email
          }, function(err, content) {
            if (err) throw err;
            that.pushBrickLocationAdmin(brickObject)
          })
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
  }

  pushBrickLocationAdmin = (brick) => {
    let brickObject = brick;
    // push everything from this state to the Firestore database
    let that = this;
    if (brickObject !== null) {
      let db = fire.firestore();
      db.collection("csv").add({
        inscription: brickObject.inscription,
        donorLastName: that.state.lastName,
        donorName: that.state.name,
        lat: brickObject.lat,
        lng: brickObject.lng,
        timeUpdated: brickObject.timeUpdated,
        updatedBy: brickObject.updatedBy,
        accurate: true
      })
      // update Algolia to reflect this change
      .then(ref => {
          brickObject.objectID = ref.id
          that.setState({
            submitted: true,
            pleaseSelectBrick: false,
            pleaseSelectLocation: false,
            selectedBrick: null,
            clickedLocation: null
          }, () =>
          adminIndex.saveObject({
            inscription: brickObject.inscription,
            donorLastName: that.state.lastName,
            donorName: that.state.name,
            section: brickObject.section,
            lat: brickObject.lat,
            lng: brickObject.lng,
            accurate: true,
            timeUpdated: brickObject.timeUpdated,
            updatedBy: brickObject.updatedBy,
            objectID: ref.id
          }, function(err, content) {
            if (err) throw err;
            that.successfulAdd(brickObject)
          })
        )
          // window.location.reload();
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
  }


  successfulAdd = (brickObject) => {
    this.setState({
      alert: "Successfully added: " + brickObject.inscription,
      name: "",
      lastName: ""
    }, () => this.updateSearchResults())
  }

  // query the Algolia "bricks" index and extract the first 5 results
  updateSearchResults = () => {
    if (this.state.logging) {
      index.search({
        query: this.state.inscription
      }).then((data) => {
        this.setState({
          results: data.hits.slice(0,5)
        })
      })
    }
    else {
      altAdminIndex.search({
        query: this.state.inscription,
        filters: 'added != 1'
      }).then((data) => {
        this.setState({
          newBricks: data.hits
        })
      })
    }

  }

  // anytime the map is clicked, update the state to reflect the location of the marker
  mapClicked = (e) => {
    this.setState({
      clickedLocation: {
        lat: e.lat,
        lng: e.lng
      },
      brickLocation: null
    })
  }

  // function will not run if any flags are triggered (missing location, brick, or both)
  pushBrickLocation = (e) => {
    if (this.state.clickedLocation === null && this.state.selectedBrick === null) {
      return this.setState({
        pleaseSelectLocation: true,
        pleaseSelectBrick: true
      })
    }
    else if (this.state.selectedBrick === null) {
      return this.setState({
        pleaseSelectBrick: true,
        pleaseSelectLocation: false
      })
    }
    else if (this.state.clickedLocation === null) {
      return this.setState({
        pleaseSelectLocation: true,
        pleaseSelectBrick: false
      })
    }

    console.log(this.state.selectedBrick.objectID)
    // push everything from this state to the Firestore database
    let ID = this.state.selectedBrick.objectID
    e.preventDefault();
    let that = this;
    let brickObject = this.state.selectedBrick;
    brickObject.lat = this.state.clickedLocation.lat;
    brickObject.long = this.state.clickedLocation.lng;
    brickObject.timeUpdated = moment().format('MMMM Do YYYY, h:mm:ss a');
    brickObject.accurate = this.state.accurate;
    if (this.state.currentLocation !== null) {
      let db = fire.firestore();
      db.collection("csv").doc(ID).update({
        lat: that.state.clickedLocation.lat,
        lng: that.state.clickedLocation.lng,
        timeUpdated: moment().format('MMMM Do YYYY, h:mm:ss a'),
        updatedBy: that.props.user.email,
        accurate: that.state.accurate
      })
      // update Algolia to reflect this change
      .then(function() {
          that.setState({
            submitted: true,
            pleaseSelectBrick: false,
            pleaseSelectLocation: false,
            selectedBrick: null,
            accurate: true,
            inscription: ''
          }, () =>
          adminIndex.partialUpdateObject({
            lat: that.state.clickedLocation.lat,
            lng: that.state.clickedLocation.lng,
            timeUpdated: moment().format('MMMM Do YYYY, h:mm:ss a'),
            objectID: brickObject.objectID,
            accurate: brickObject.accurate,
            updatedBy: that.props.user.email
          }, function(err, content) {
            if (err) throw err;
          })
        )
          // window.location.reload();
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
  }

  // function will not run if any flags are triggered (missing location, brick, or both)
  pushNewBrickLocation = (e) => {
    if (this.state.clickedLocation === null) {
      return this.setState({
        pleaseSelectLocation: true,
        pleaseSelectBrick: false
      })
    }
    // push everything from this state to the Firestore database
    let that = this;
    let brickObject = {};
    brickObject.inscription = this.state.inscription;
    brickObject.lat = this.state.clickedLocation.lat;
    brickObject.lng = this.state.clickedLocation.lng;
    brickObject.timeUpdated = moment().format('MMMM Do YYYY, h:mm:ss a');
    brickObject.updatedBy = this.props.user.email;
    if (this.state.currentLocation !== null) {
      let db = fire.firestore();
      db.collection("newBricks").add({
        inscription: brickObject.inscription,
        lat: brickObject.lat,
        lng: brickObject.lng,
        timeUpdated: brickObject.timeUpdated,
        updatedBy: brickObject.updatedBy
      })
      // update Algolia to reflect this change
      .then(ref => {
          brickObject.objectID = ref.id
          that.setState({
            submitted: true,
            pleaseSelectBrick: false,
            pleaseSelectLocation: false,
            selectedBrick: null
          }, () =>
          altAdminIndex.saveObject({
            inscription: brickObject.inscription,
            lat: brickObject.lat,
            lng: brickObject.lng,
            timeUpdated: brickObject.timeUpdated,
            updatedBy: brickObject.updatedBy,
            objectID: ref.id
          }, function(err, content) {
            if (err) throw err;
          })
        )
          // window.location.reload();
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }

  }

  // not using this at the moment
  pushCurrentSelectedLocation = (e) => {
    e.preventDefault();
    let that = this;
    if (this.state.currentLocation !== null) {
      let db = fire.firestore();
      db.collection("locations").add({
        lat: that.state.currentLocation.lat,
        lng: that.state.currentLocation.lng,
        time: moment().format('MMMM Do YYYY, h:mm:ss a')
      })
      .then(function() {
          that.setState({
          }, () => that.componentDidMount())
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
  }

  // Before anything renders, find the current location from the browser,
  // then push that location to Firestore automatically
  componentDidMount() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude
          }
        });
      });
    }
    let db = fire.firestore();
    db.collection('admins').get().then(snapshot => {
      snapshot.forEach(docSnapshot => {
        if (docSnapshot.id === this.props.user.email) {
          this.setState({
            isAdmin: true
          })
        }
      })
    })
  }

  // sets up the Google map component with necessary properties
  getMapOptions = (maps: Maps) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        tilt: 0,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        mapTypeId: maps.MapTypeId.SATELLITE,
    };
}

// not using this, ignore for now
  getResultsFromFirestore = () => {
    let db = fire.firestore();
    let that = this;
    let results = [];
    db.collection('csv').where('Inscription', '==', that.state.inscription).get().then(snapshot => {
        snapshot.forEach(doc => {
          results.push(doc.data())
        });
      this.setState(
        {results: results})
    })
  }

    //   ONE TIME FUNCTION, ONLY EVER USE ONCE TO
    // COMPLETELY UPLOAD ALL BRICKS TO FIRESTORE
  uploadAllBricksToDB = () => {
    // let db = fire.firestore();
    // for (let i in bricks) {
    //   db.collection('csv').add(bricks[i]).then(ref => {
    //     console.log('Added document with ID: ', ref.id);
    //   });
    // }
  }

  // not using this, ignore for now
  sendDBtoAlgolia = () => {
    // let db = fire.firestore();
    // var wholeData = [];
    // db.collection('csv').get()
    // .then(snapshot => {
    //     snapshot.forEach(doc => {
    //         console.log(doc.id);
    //         let docCopy = doc.data();
    //         docCopy.objectID = doc.id;
    //         wholeData.push(docCopy)
    //     });
    //     // let questions = Array(wholeData.length)
    //     var client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);
    //     var index = client.initIndex(ALGOLIA_INDEX_NAME)

    //     index.saveObjects(wholeData, function(err, content) {
    //       // res.status(200).sent(content);
    //     })
    // })
  }

}

export default Admin;
