import React from 'react';
import PropTypes from 'prop-types';
import brick from './brick.png'

const Brick = props => (
    <img src={brick} alt="brick" style={{position: 'absolute',  userSelect: 'none', width: '20px', height: '20px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', outline: '3px dashed yellow'}}></img> 
);

Brick.defaultProps = {
  onClick: null,
};

Brick.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Brick;