import React from 'react';
import PropTypes from 'prop-types';
import person from './person.png'

const Person = props => (
    <img alt="person" src={person} style={{position: 'absolute',  userSelect: 'none', width: '25px', height: '25px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></img> 
);

Person.defaultProps = {
  onClick: null,
};

Person.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Person;